export enum OverlayVariants {
  GRAY = 'gray',
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  TEAL = 'teal',
  BLUE = 'blue',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  PINK = 'pink',
  BLACK = 'black',
  WHITE = 'white',
}

export enum OverlayOpacity {
  SMALL = 'opacity-40',
  MEDIUM = 'opacity-50',
  BIG = 'opacity-60',
}
