import React, { ElementType, FC } from 'react';
import cx from 'classnames';
import BackgroundOverlay from '../../atoms/BackgroundOverlay/BackgroundOverlay';
import {
  OverlayOpacity,
  OverlayVariants,
} from '../../atoms/BackgroundOverlay/BackgroundOverlay.types';

type BackgroundWrapperProps = {
  as: ElementType;
  className?: string;
  [key: string]: any;
};

const BackgroundWrapper: FC<BackgroundWrapperProps> = ({
  children,
  as: AS,
  className,
  ...props
}) => (
  <AS className={cx(className, 'HeroImage_Height')} {...props}>
    {children}
  </AS>
);

type ContentProps = {
  variant?: OverlayVariants;
  backgroundClassName?: string;
  contentClassName?: string;
};

const Content: FC<ContentProps> = ({
  children,
  variant,
  backgroundClassName,
  contentClassName,
}) => (
  <>
    <BackgroundOverlay
      variant={variant}
      opacity={OverlayOpacity.BIG}
      className={cx(' overflow-hidden HeroImage_Height ', backgroundClassName)}
    />
    <div
      className={cx(
        contentClassName,
        ' absolute flex flex-1 justify-center items-center w-full HeroImage_Height flex-col justify-between'
      )}
    >
      {children}
    </div>
  </>
);

export const HalfHero: FC & {
  Content: FC<ContentProps>;
  BackgroundWrapper: FC<BackgroundWrapperProps>;
} = ({ children }) => (
  <div className="Hero_half  h-screen bg-white dark:bg-black">{children}</div>
);

HalfHero.BackgroundWrapper = BackgroundWrapper;
HalfHero.Content = Content;
