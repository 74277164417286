import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { OverlayVariants } from '@kastle/ui/src/atoms/BackgroundOverlay/BackgroundOverlay.types';
import Button from '@kastle/ui/src/atoms/Button/Button';
import { ButtonTypes } from '@kastle/ui/src/atoms/Button/Button.types';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import { HeadingTypes } from '@kastle/ui/src/atoms/Heading/Heading.types';
import {
  CustomLayout,
  Head,
  Separator,
} from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Icon from '@kastle/ui/src/atoms/Icons/Icon';
import MouseScrollDownIcon from '@kastle/ui/src/atoms/Icons/MouseScrollDown';
import BackgroundImage from './BackgroundImage';
import { IconTypes } from '@kastle/ui/src/atoms/Icons/Icon.types';
import { useTranslation } from 'react-i18next';
import { HalfHero } from '@kastle/ui/src/molecules/Hero/HalfHero';
import { FadeInAnimation } from '@kastle/ui/src/atoms/Animation/FadeIn';

const HeroComponent: FC = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "2020/11/background_11.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <HalfHero>
      <BackgroundImage
        image={data?.backgroundImage.childImageSharp?.gatsbyImageData}
      />
      <HalfHero.Content
        variant={OverlayVariants.BLACK}
        contentClassName={FadeInAnimation.THIRD}
      >
        <CustomLayout>
          <Head>
            <Separator>
              <Heading as={HeadingTypes.h1} colorClassName="text-white">
                {t('common.hero.main')}
              </Heading>
            </Separator>
            <Separator>
              <Button
                as={Link}
                variantType={ButtonTypes.Primary}
                aria-label={'here'}
                to={t('common.nav.contact.link')}
              >
                {t('common.hero.cta')}
              </Button>
            </Separator>
          </Head>
        </CustomLayout>
        <Separator>
          <Icon
            Component={MouseScrollDownIcon}
            variant={IconTypes.Light}
            className="Hero__icon-size"
          />
        </Separator>
      </HalfHero.Content>
    </HalfHero>
  );
};

export default HeroComponent;
