const name = 'Karst Development';

// const address = {
//   '@type': 'PostalAddress',
//   streetAddress: '19 rue saint pierre',
//   addressLocality: 'Morey',
//   addressRegion: 'Lorraine',
//   postalCode: '54610',
//   addressCountry: 'FR',
// };
//
// const geo = {
//   '@type': 'GeoCoordinates',
//   latitude: 48.826438,
//   longitude: 6.161825,
// };

const logo = {
  '@type': 'ImageObject',
  url: 'https://www.karst.tech/logo.png',
  height: '225',
  width: '500',
};

// const availableLanguage = [
//   {
//     '@type': 'Language',
//     name: 'French',
//   },
// ];

export const Website = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://www.karst.tech/',
  name,
  logo,
};

export const Organization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://www.karst.tech/',
  sameAs: [
    'https://www.facebook.com/karst.dev/',
    'https://www.instagram.com/karst.development/',
    'https://twitter.com/KarstDev/',
    'https://fr.linkedin.com/company/karst-development/',
  ],
  name,
  logo,
};
