import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { HalfHero } from '@kastle/ui/src/molecules/Hero/HalfHero';
import { FadeInAnimation } from '@kastle/ui/src/atoms/Animation/FadeIn';

type Props = {
  image?: FluidObject | FluidObject[];
};

const BackgroundImage: FC<Props> = ({ image }) => (
  <HalfHero.BackgroundWrapper as="div" className={FadeInAnimation.FIRST}>
    <GatsbyImage
      image={image}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '100%',
      }}
      objectFit="cover"
    />
  </HalfHero.BackgroundWrapper>
);

export default BackgroundImage;
