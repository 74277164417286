import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Organization, Website } from './Informations';

type Props = {
  isHome: boolean;
};

const SEO: FC<Props> = ({ isHome }) => (
  <Helmet
    defer={false}
    meta={[
      {
        name: 'viewport',
        content: 'initial-scale=1.0, user-scalable=0, width=device-width',
      },
      {
        name: 'robots',
        content:
          'index,follow,max-snippet:-1,max-image-preview:large,max-video-preview:-1',
      },
    ]}
  >
    {isHome && (
      <script type="application/ld+json" data-testid="schema-website">
        {JSON.stringify(Website)}
      </script>
    )}

    {isHome && (
      <script type="application/ld+json" data-testid="schema-organization">
        {JSON.stringify(Organization)}
      </script>
    )}
  </Helmet>
);

export default SEO;
