import React, { FC } from 'react';
import cx from 'classnames';
import { OverlayOpacity, OverlayVariants } from './BackgroundOverlay.types';

type Props = {
  className?: string;
  variant?: OverlayVariants;
  opacity: OverlayOpacity;
};

const BackgroundOverlay: FC<Props> = ({ className = '', variant, opacity }) => (
  <div
    className={cx(
      'absolute h-full w-full top-0 left-0 right-0 pointer-events-none',
      {
        'bg-blue-500': variant === OverlayVariants.BLUE,
        'bg-gray-500': variant === OverlayVariants.GRAY,
        'bg-green-500': variant === OverlayVariants.GREEN,
        'bg-indigo-500': variant === OverlayVariants.INDIGO,
        'bg-orange-500': variant === OverlayVariants.ORANGE,
        'bg-pink-500': variant === OverlayVariants.PINK,
        'bg-purple-500': variant === OverlayVariants.PURPLE,
        'bg-red-500': variant === OverlayVariants.RED,
        'bg-teal-500': variant === OverlayVariants.TEAL,
        'bg-yellow-500': variant === OverlayVariants.YELLOW,
        'bg-black': variant === OverlayVariants.BLACK,
        'bg-white': variant === OverlayVariants.WHITE,
      },
      opacity,
      className
    )}
  />
);

export default BackgroundOverlay;
