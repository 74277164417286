import React, { FC } from 'react';

const MouseScrollDownIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 247 390"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
    stroke="currentColor"
    {...props}
  >
    <path
      id="wheel"
      d="M123.359,79.775l0,72.843"
      fill="none"
      strokeWidth="20px"
      stroke="currentColor"
    />
    <path
      id="mouse"
      d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
      fill="none"
      strokeWidth="20px"
      stroke="currentColor"
    />
  </svg>
);

export default MouseScrollDownIcon;
